<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <el-form-item label="岗位名称:">
            <el-input v-model="selectForm.Positions" placeholder="岗位名称"></el-input>
          </el-form-item>
          <el-form-item label="是否在招:">
              <el-select v-model="selectForm.IsShow" placeholder="请选择" style="width: 100%">
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
              </el-select>
          </el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
        <el-table :data="users" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
            <!-- <el-table-column type="index" width="50"> </el-table-column> -->
            <el-table-column prop="REV_PRO_STATUS" label="发布状态"> </el-table-column>
            <el-table-column prop="Company" label="归属公司"> </el-table-column>
            <!-- <el-table-column prop="Company" label="部门"> </el-table-column>
            <el-table-column prop="Company" label="申请人"> </el-table-column> -->
            <el-table-column prop="REV_CREATER_DATE" label="发布时间"> </el-table-column>
            <el-table-column prop="Positions" show-overflow-tooltip label="岗位名称"> </el-table-column>
            <el-table-column prop="Number" show-overflow-tooltip label="招聘数量"> </el-table-column>
            <el-table-column prop="AstrictEdu" label="学历要求"></el-table-column>
            <el-table-column prop="AstrictAge" label="年龄要求"></el-table-column>
            <el-table-column prop="AstrictSpecialty" show-overflow-tooltip label="专业要求"> </el-table-column>
            <el-table-column prop="AstrictIndustryExp" show-overflow-tooltip label="从业经验"></el-table-column>
            <el-table-column prop="WorkSalaryStar" label="薪资待遇"></el-table-column>
            <el-table-column prop="WorkSalaryEnd" label="至薪资待遇"></el-table-column>
            <el-table-column prop="IsShow" label="是否在招"></el-table-column>
        </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper ,total" :page-count="total" :total="totaldata">
    </el-pagination>


    <!-- 弹窗 -->
    <!-- 弹窗 -->
    <el-dialog :title="operation ? '新增' : '查看'" :visible.sync="SakaryVisible" v-model="SakaryVisible" :close-on-click-modal="false">
        <el-form :rules="SakaryRules" ref="SakaryForm" :model="SakaryForm" label-width="100px">
          <el-row style="display:none;">
                <el-col :span="12">
                    <el-form-item label="招聘ID:">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.REV_CID"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                  <el-form-item label="招聘岗位:" prop="Positions">
                    <el-input type="text" :disabled="true" v-model="SakaryForm.Positions">
                      <!-- <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button> -->
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="招聘人数:" prop="Number">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.Number"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="display:none;">
                <el-col :span="12">
                    <el-form-item label="岗位ID:" prop="PositionsId">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.PositionsId"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="岗位说明书ID:" prop="PostExplainId">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.PostExplainId"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="薪资待遇:" prop="WorkSalaryStar">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.WorkSalaryStar">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="至:" prop="WorkSalaryEnd">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.WorkSalaryEnd"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="公司:" prop="Company">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.Company"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="是否一线员工:" prop="IsYxJob">
                      <el-input type="text" :disabled="true" v-model="SakaryForm.IsYxJob"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="年龄要求:" prop="AgeRequest">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.AgeRequest"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="学历要求:" prop="Schoolwork">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.Schoolwork"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                
                <el-col :span="12">
                    <el-form-item label="专业要求:" prop="Education">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.Education"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-bottom:10px;">
                <el-col :span="24">
                    <label for="Summarize" class="el-form-item__label">从业经验:</label>
                    <el-input type="textarea" :disabled="true" v-model="SakaryForm.Experience" style="width: 90%"></el-input>
                </el-col>
            </el-row>
            <el-row style="margin-bottom:10px;">
                <el-col :span="24">
                    <label for="Summarize" class="el-form-item__label">岗位描述:</label>
                    <el-input type="textarea" :disabled="true" v-model="SakaryForm.JobDescription" style="width: 90%"></el-input>
                </el-col>
            </el-row>
            <el-row style="margin-bottom:10px;">
                <el-col :span="24">
                    <label for="Summarize" class="el-form-item__label">申请原因:</label>
                    <el-input type="textarea" :disabled="true" v-model="SakaryForm.ApplyReason" style="width: 90%"></el-input>
                </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="关联人员" label-width="120px">
                    <el-button v-on:click="chooseCandidate()">选择关联人员</el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-table :data="SakaryForm.LinkUserInfo" style="width: 100%" class="bg-color">
                <!-- <el-table-column prop="RegisterInfo.ApplyDate" label="日期"></el-table-column> -->
                <el-table-column prop="RegisterInfo.Name" label="名称"></el-table-column>
                <el-table-column prop="type" label="类型"></el-table-column>
                <el-table-column label="操作" width="150">
                  <template scope="scope">
                    <el-button v-show="scope.row.type === '推送'" size="small" v-on:click="PersonsPush(scope.row)" type="text">{{scope.row.type}}</el-button>
                    <el-button size="small" v-on:click="PersonsDelete(scope.row)" type="text">删除</el-button>
                  </template>
                </el-table-column>
                <el-table-column label="信息">
                  <template scope="scope">
                    <el-button size="small" v-on:click="AddLinkApply(scope.row)" type="text">关联应聘</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <!-- <el-row style="margin-bottom:14px;">
                <el-col :span="24">
                    <el-button type="primary" v-on:click="onPostExplain()">岗位说明书</el-button>
                </el-col>
            </el-row> -->
            <el-row v-if="operation1">
                <el-col :span="24" style="text-align: center">
                    <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
    <!-- <el-dialog title="选择人员" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
      <UserChoose :data="choosedusers" :all="true" :single="false" @callback="usercallFunction"></UserChoose>
    </el-dialog> -->
    
    <!-- 岗位说明书 -->
    <!-- <postExplainModal ref="postExplainModal" @callback="getData"></postExplainModal> -->

    <!-- 登记表 -->
    <candidateMangentModal ref="candidateMangentModal" @callback="usercallFunction"></candidateMangentModal>

    <!-- 应聘表 -->
    <candidateMangentModalApply ref="candidateMangentModalApply" @callback="usercallFunctionApply"></candidateMangentModalApply>
    

  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UploadFiles from "../../components/UploadFiles";
import {
  AddSalaRecruiting,
  GetExpectationInfo,
  GetExpectation,
  DelLinkRecruiting,
  AddLinkRecruiting,
  PushApplyUserRecruiting,
  AddLinkApply,
  SalaRecruitingApplyEnd,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
// import UserChoose from "../../components/UserChoose";
import Toolbar from "../../components/newToolbar";
import UserChoosejob from "../../components/UserChoosejob";
import DptTool from "../../components/Org/DptTool"; // 选取部门控件
import postExplainModal from "../componentModals/PostExplainModal"    // 岗位职责
import candidateMangentModal from "../componentModals/candidateMangentModal"    // 登记表
import candidateMangentModalApply from "../componentModals/candidateMangentModalApply"    // 应聘表
export default {
  components: { Toolbar, UploadFiles,UserChoosejob,DptTool,postExplainModal,candidateMangentModal,candidateMangentModalApply },
  data() {
    return {
      optType: true, 
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      operation: false, // true:新增, false:编辑
      operation1: true,
      pageSize: 10, // 每页的数据条数
      page: 1,
      searchVal: "",
      CertCategoryLists: [],
      CertProfessionLists: [],
      SavePlaceLists: [],
      currentRow: {}, // 选中行
      SakaryVisible: false,
      choosedusers: [],
      selectForm: {
        Positions: null,
        IsShow:"是",
      },
      selUserInfo:{
        Name : "",//人员姓名
        recruitmentId: "",//招聘Id
        ApplyDate: "",//应聘时间
        applyId: "",
      },
      // selUserInfo: {},
      addUserVisible: false,
      SakaryForm: {
        PostLevel:"",//岗位级别
        WorkSalaryStar:"",//薪资待遇
        WorkSalaryEnd:"",//至薪资待遇
        IsYxJob:"",//是否一线
        TeacherName:"",//导师姓名（选择一线时需填写导师）
        Positions:"",//招聘岗位
        Number:"",//招聘数量
        Education:"",//专业
        Experience:"",//工作经验
        SexRequest:"",//性别要求
        AgeRequest:"",//年龄要求
        DemandCategory:"",//需求类别
        JobDescription:"",//岗位描述
        DiathesisRequest:"",//素质要求
        ApplyReason:"",//申请原因、备注
        Schoolwork:"",//学历要求
        PositionsId:"",//岗位ID
        PostExplainId:"",//岗位职责id
        Company:"",//公司
        Astricts: {},
        LinkUserInfo: [],
        REV_CID:"",
      },
      users: [],
      buttonList: [],
      SakaryRules: {
      },
    };
  },
  methods: {
    chooseCandidate() {//关联公司名称
      // let row = this.currentRow;
      console.log(111);
      // this.$refs.candidateMangentModal.onShowDlg();
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    usercallFunction(newdata) {//登记表
      this.selUserInfo = {
        // Name : newdata.Name,//人员姓名
        recruitmentId: this.SakaryForm.REV_CID,//招聘Id
        registerId: newdata.applyId,//登记表ID
        // ApplyDate: newdata.ApplyDate,//应聘时间
        // applyId: newdata.applyId,//应聘Id
      }

      let para = Object.assign({}, this.selUserInfo);
        AddLinkRecruiting(para).then((res) => {
          console.log(res.data)
          if (res.data.success) {
            this.$message({
              message: "成功",
              type: "success",
              duration: 5000,
          });
          console.log(para)
          console.log(this.SakaryForm.REV_CID);
          GetExpectationInfo({ recruitingId: this.SakaryForm.REV_CID }).then((res) => {
            console.log(res.data.response);
            console.log(res.data.response.LinkUserInfo);
            this.SakaryForm = res.data.response;
            this.SakaryVisible = true;
          });

          } else {
            this.$message({
              message: "失败",
              type: "error",
              duration: 5000,
            });
          }
        });

      
      
      this.candidateMangentModal = false;
    },

    usercallFunctionApply(newdata) {//应聘表
      this.selUserInfo = {
        applyId: newdata.applyId,//应聘Id
        linkId: newdata.linkId,//linkId
      }
      let para = Object.assign({}, this.selUserInfo);
        AddLinkApply(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: "成功",
              type: "success",
              duration: 5000,
            });

          } else {
            this.$message({
              message: "失败",
              type: "error",
              duration: 5000,
            });
          }
        });
      this.candidateMangentModalApply = false;
    },
    AddLinkApply(row){//关联应聘
    this.$refs.candidateMangentModalApply.onShowDlg(row.Id);
    },

    onPostExplain(){//岗位说明书
      if (this.SakaryForm.PostExplainId != null) {
        this.$refs.postExplainModal.operation = false; // 添加
        this.$refs.postExplainModal.optType = false;   // 编辑
        this.$refs.postExplainModal.onShowDlg(this.SakaryForm.PostExplainId);
      }else{
        this.$refs.postExplainModal.operation = true; // 添加
        this.$refs.postExplainModal.optType = true;   // 编辑
        this.$refs.postExplainModal.onShowDlg();
      }
      
    },
    PersonsDelete(row) {//关联人员删除
    console.log(row);
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.SakaryForm.LinkUserInfo.splice(this.SakaryForm.LinkUserInfo.indexOf(row), 1);
        let para = {
          LinkId:row.Id,
          // RecruitmentId: row.RecruitmentId,
          // ApplyId: row.ApplyId,
        };
        console.log(para);
      DelLinkRecruiting(para).then((res) => {
          console.log(res.data)
          if (res.data.success) {
            this.$message({
              message: "成功",
              type: "success",
              duration: 5000,
          });
          // this.getData();
          GetExpectationInfo({ recruitingId: this.SakaryForm.REV_CID }).then((res) => {
            console.log(res);
            this.SakaryForm = res.data.response;
            this.SakaryVisible = true;
          });
          } else {
            this.$message({
              message: "失败",
              type: "error",
              duration: 5000,
            });
          }
        });

      });

      
    },
    PersonsPush(row){
      console.log(row.Id);
      this.$confirm("确认要推送吗？", "提示", {
        type: "warning",
      }).then((_) => {
        let para = {
          linkId: row.Id,

        };
      PushApplyUserRecruiting(para).then((res) => {
          console.log(res)
          if (res.data.success) {
            this.$message({
              message: "成功",
              type: "success",
              duration: 5000,
          });
          GetExpectationInfo({ recruitingId: this.SakaryForm.REV_CID }).then((res) => {
            console.log(res);
            this.SakaryForm = res.data.response;
            this.SakaryVisible = true;
          });
          } else {
            this.$message({
              message: "失败",
              type: "error",
              duration: 5000,
            });
          }
        });

      });
    },
    
    chooseCandidate() {//关联人员
      let row = this.currentRow;
      if(row.REV_PRO_STATUS=="发布"){
        this.$refs.candidateMangentModal.onShowDlg();
      }else{
        this.$message({
          message: "发布状态未完成，无法关联人员！",
          type: "error",
        });
      }
      
    },
    handleShow() {//查看
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row.REV_CID);
      GetExpectationInfo({ recruitingId: row.REV_CID }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.SakaryVisible = true;
      });
      this.operation = false;
      this.operation1 = false;
    },
    isShow() {//停止在招
      let row = this.currentRow;
      if (!row.REV_CID) {
        this.$message({
          message: "请选择要停止在招的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row.REV_CID);
      this.$confirm("确定要停止在招吗？", "提示", {}).then(() => {
        let para = {
          Id: row.REV_CID,
        };
        SalaRecruitingApplyEnd(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: "成功",
              type: "success",
              duration: 5000,
            });
            this.getData(false);
          } else {
            this.$message({
              message: "失败",
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    handleAdd() {
      (this.SakaryForm = {
        PostLevel:"",//岗位级别
        WorkSalaryStar:"",//薪资待遇
        WorkSalaryEnd:"",//至薪资待遇
        IsYxJob:"",//是否一线
        TeacherName:"",//导师姓名（选择一线时需填写导师）
        Positions:"",//招聘岗位
        Number:"",//招聘数量
        Education:"",//专业
        Experience:"",//工作经验
        SexRequest:"",//性别要求
        AgeRequest:"",//年龄要求
        DemandCategory:"",//需求类别
        JobDescription:"",//岗位描述
        DiathesisRequest:"",//素质要求
        ApplyReason:"",//申请原因、备注
        Schoolwork:"",//学历要求
        PositionsId:"",//岗位ID
        PostExplainId:"",//id
        Company:"",//公司
        Astricts: {},
        REV_CID:"",
        LinkUserInfo: [],
      }),
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    //获取用户列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }

      let para = {
        page: this.page,
        size: this.pageSize,
        postName: this.selectForm.Positions,
        isShow:this.selectForm.IsShow,
      };
      GetExpectation(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleCurrentChange(val) {

      this.currentPage = val;
      this.page = val;
      this.getData(false);

    },
    SavePlaceModel(id) {
      let obj = {};
      obj = this.SavePlaceLists.find((item) => {
        //model就是上面的数据源

        return item.Name === id; //筛选出匹配数据
      });
      this.SakaryForm.SavePlaceId = obj.Id;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            console.log(para);
            if (this.operation) {
              //新增
              AddSalaRecruiting(para).then((res) => {
                console.log(res.data.success)
                if (res.data.success) {
                  this.$message({
                    message: "成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: "失败",
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              // UpdateCertificate(para).then((res) => {
              //   if (res.data.success) {
              //     this.$message({
              //       message: "修改成功",
              //       type: "success",
              //       duration: 5000,
              //     });
              //     this.SakaryVisible = false;
              //     this.getData(false);
              //   } else {
              //     this.$message({
              //       message: "修改失败",
              //       type: "error",
              //       duration: 5000,
              //     });
              //   }
              // });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    
  },

  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.calc-height {
  height: calc(100vh - 300px) !important;
}
.myAlertSuccess {
    background-color: #409eff;
    color: #FFF;
    padding: 8px 16px;
    border-radius: 4px ;
    text-align: center;
    font-size: 16px;
}
</style>

